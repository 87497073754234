import { Toast, useToasts } from '@flash-tecnologia/hros-web-ui-v2'
import { useCallback } from 'react'

type ToastProps = {
  title: string
  description?: string
}

export const useToast = () => {
  const { showToast } = useToasts()

  const toastSuccess = useCallback(
    ({ title, description }: ToastProps) => {
      return showToast('', {
        content: (key) => {
          return (
            <div>
              <Toast
                id={key}
                type="success"
                title={title}
                description={description}
              />
            </div>
          )
        },
      })
    },
    [showToast],
  )

  const toastError = useCallback(
    ({ title, description }: ToastProps) => {
      return showToast('', {
        content: (key) => {
          return (
            <div>
              <Toast
                id={key}
                type="error"
                title={title}
                description={description}
              />
            </div>
          )
        },
      })
    },
    [showToast],
  )

  return {
    toastSuccess,
    toastError,
  }
}
