import * as Tab from 'src/components/Tab'
import * as Table from 'src/components/Table'

import { Columns } from './components/Columns'
import { DrawerBenefitDetails } from './components/DrawerBenefitDetails'
import { Filters } from './components/Filters'
import { ContainerStyled } from './styled'
import { useFlashBenefits } from './useFlashBenefits'

export function FlashBenefitsTab() {
  const {
    benefits,
    totalBenefit,
    isLoading,
    searchTerm,
    handleSearchChange,
    handleSearchFocus,
  } = useFlashBenefits()

  return (
    <ContainerStyled>
      <Tab.Header
        title="Benefícios Flash"
        totalCount={totalBenefit}
        loading={false}
      />

      <Table.Root isLoading={isLoading}>
        <Table.Search
          label="Buscar por benefício"
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={handleSearchFocus}
        />

        <Filters />

        <Table.Grid
          data={benefits}
          rowIdKey="id"
          columns={Columns}
          pageCount={totalBenefit}
          pageSize={totalBenefit}
          emptyStateMessage=""
        />
      </Table.Root>

      <DrawerBenefitDetails />
    </ContainerStyled>
  )
}
