import { ToastProvider } from '@flash-tecnologia/hros-web-ui-v2'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { BenefitManagement } from 'src/pages/BenefitManagement'
import { ExternalBenefitTab } from 'src/pages/BenefitManagement/Tabs/ExternalBenefitTab'
import { FlashBenefitsTab } from 'src/pages/BenefitManagement/Tabs/FlashBenefitsTab'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <ToastProvider>
        <Routes>
          <Route path="/benefit-management" element={<BenefitManagement />}>
            <Route index element={<FlashBenefitsTab />} />
            <Route
              path="/benefit-management/external-benefit"
              element={<ExternalBenefitTab />}
            />
          </Route>
        </Routes>
      </ToastProvider>
    </BrowserRouter>
  )
}

export default AppRouter
