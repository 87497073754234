import { useBenefitDetailsStore } from '../../../../stores/useBenefitDetailsStore'

type UseCellDetailsProps = {
  benefitId: string
  benefitName: string
}

export const useCellDetails = ({
  benefitId,
  benefitName,
}: UseCellDetailsProps) => {
  const { handleOpen } = useBenefitDetailsStore()

  function handleOpenDetails() {
    handleOpen(benefitId, benefitName)
  }

  return {
    handleOpenDetails,
  }
}
