import { screenBenefitDetailTransform } from '@flash-hros/zod-schemas/transformations/screenBenefitDetailTransform'
import { z } from 'zod'
import { create } from 'zustand'

type BenefitFields = z.output<typeof screenBenefitDetailTransform>

type UseBenefitDetailsStoreState = {
  open: boolean
  benefitId: string | null
  benefitName: string
  benefitFields: BenefitFields | null
}

type UseBenefitDetailsStoreProps = UseBenefitDetailsStoreState & {
  handleOpen: (benefitId: string, benefitName: string) => void
  handleClose: () => void
  detailsReset: () => void
  setBenefitFields: (fields: Partial<BenefitFields> | null) => void
}

const initialState: UseBenefitDetailsStoreState = {
  open: false,
  benefitId: null,
  benefitName: '',
  benefitFields: null,
}

export const useBenefitDetailsStore = create<UseBenefitDetailsStoreProps>(
  (set) => ({
    ...initialState,
    handleOpen: (benefitId, benefitName) => {
      set({ benefitId, benefitName, open: true })
    },
    handleClose: () => {
      set({ benefitId: null, benefitName: '', open: false })
    },
    detailsReset: () => {
      set(initialState)
    },
    setBenefitFields(fields) {
      set(({ benefitFields, ...oldState }) => ({
        ...oldState,
        benefitFields: {
          ...benefitFields,
          ...fields,
        } as BenefitFields,
      }))
    },
  }),
)
