import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const HeaderContainerStyled = styled.div`
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.spacings.xs4};
`

export const TitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`
