import { Tag } from '@flash-tecnologia/hros-web-ui-v2'

import { HeaderContainerStyled, TitleStyled } from './styled'

type HeaderTitleProps = {
  title: string
}

type HeaderProps = HeaderTitleProps & {
  totalCount: number
  loading: boolean
}

function HeaderTitle({ title }: HeaderTitleProps) {
  return <TitleStyled variant="headline8">{title}</TitleStyled>
}

export function Header({
  title,
  totalCount = 0,
  loading = false,
}: HeaderProps) {
  return (
    <HeaderContainerStyled>
      <HeaderTitle title={title} />

      <Tag disabled variant={'gray'} loading={loading}>
        {totalCount} itens
      </Tag>
    </HeaderContainerStyled>
  )
}
