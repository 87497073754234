import { TextDescription, TextTitle, WrapperTextsStyled } from './styled'

type TextsProps = {
  title: string
  description?: string
}

export function Texts({ title, description }: TextsProps) {
  const renderDescription = () => {
    if (description) {
      return <TextDescription variant="body3">{description}</TextDescription>
    }

    return null
  }

  return (
    <WrapperTextsStyled>
      <TextTitle variant="headline6">{title}</TextTitle>

      {renderDescription()}
    </WrapperTextsStyled>
  )
}
