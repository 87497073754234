import { screenBenefitDetailTransform } from '@flash-hros/zod-schemas/transformations/screenBenefitDetailTransform'
import { IconTypes, ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2'

import * as Box from '../Box'
import {
  DescriptionStyled,
  TitleStyled,
  WrapperTextStyled,
  WrapperTransactionMethodStyled,
} from './styles'

type FormsOfUseProps = Pick<
  typeof screenBenefitDetailTransform._output,
  'transactionMethods'
>

const typeMapping: { [key: string]: IconTypes } = {
  'app-purchase': 'IconBasket',
  'physical-virtual-card': 'IconCreditCard',
  'bank-transfer': 'IconTransferOut',
}

export function FormsOfUse({ transactionMethods }: FormsOfUseProps) {
  return (
    <Box.Root>
      <Box.Header
        title="Formas de uso"
        description="Essas são as formas de uso disponíveis para esse benefício."
      />

      {transactionMethods?.map(({ type, name, description }) => (
        <WrapperTransactionMethodStyled key={type}>
          {type && (
            <ShapeIcon
              name={typeMapping[type]}
              variant="default"
              stroke="default"
              size={40}
            />
          )}

          <WrapperTextStyled>
            <TitleStyled variant="headline9">{name}</TitleStyled>

            <DescriptionStyled variant="body4">{description}</DescriptionStyled>
          </WrapperTextStyled>
        </WrapperTransactionMethodStyled>
      ))}
    </Box.Root>
  )
}
