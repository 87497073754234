import { Tag } from '@flash-tecnologia/hros-web-ui-v2'

import { ContainerStyled } from './styles'

type CellCategoriesProps = {
  categories: string[]
}

export function CellCategories({ categories }: CellCategoriesProps) {
  return (
    <ContainerStyled>
      {categories.slice(0, 2).map((category) => (
        <Tag key={category} variant="gray" disabled>
          {category}
        </Tag>
      ))}

      {categories.length > 2 && (
        <Tag variant="gray" disabled>
          + {categories.length - 2}
        </Tag>
      )}
    </ContainerStyled>
  )
}
