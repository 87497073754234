import { Tag } from '@flash-tecnologia/hros-web-ui-v2'

import { ContainerStyled } from './styles'

type CellUsageFormsProps = {
  usageForms: string[]
}

export function CellUsageForms({ usageForms }: CellUsageFormsProps) {
  return (
    <ContainerStyled>
      {usageForms.slice(0, 2).map((usageForm) => (
        <Tag key={usageForm} variant="gray" disabled>
          {usageForm}
        </Tag>
      ))}

      {usageForms.length > 2 && (
        <Tag variant="gray" disabled>
          + {usageForms.length - 2}
        </Tag>
      )}
    </ContainerStyled>
  )
}
