import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: ${({ theme }) => theme.spacings.xs3};
`

export const BoxEmojiStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  background-color: ${({ theme }) => theme.colors.neutral90};

  border-radius: ${({ theme }) => theme.borders.radius.circular};

  img {
    width: 24px;
    height: 24px;
  }
`

export const BoxContentStyled = styled.div`
  display: flex;
  flex-direction: column;

  p:nth-child(1) {
    color: ${({ theme }) => theme.colors.neutral40};
    font-weight: 600;
  }

  p:nth-child(2) {
    color: ${({ theme }) => theme.colors.neutral60};
  }
`
