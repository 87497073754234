import { CalculationMethod } from './CalculationMethod'
import { Categories } from './Categories'
import { ContainerStyled, TypographyStyled } from './styled'
import { UsageForms } from './UsageForms'

export function Filters() {
  return (
    <ContainerStyled>
      <TypographyStyled variant="body3">Filtrar por</TypographyStyled>

      <Categories />

      <CalculationMethod />

      <UsageForms />
    </ContainerStyled>
  )
}
