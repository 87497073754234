import { PageHeader } from '@flash-tecnologia/hros-web-ui-v2'
import { ReactNode } from 'react'

import { ContainerStyled } from './styled'

type RootProps = {
  children: ReactNode
}

export function Root({ children }: RootProps) {
  return (
    <PageHeader>
      <ContainerStyled>{children}</ContainerStyled>
    </PageHeader>
  )
}
