import { Table } from '@flash-tecnologia/hros-web-ui-v2'

import { RootProvider } from './Root'

export { Pagination } from './Pagination'
export { Grid } from './Grid'

const Root = RootProvider
const Header = Table.Header
const Search = Table.Search

export { Root, Header, Search }
