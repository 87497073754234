import { getPermissionsFlags } from '@flash-tecnologia/hros-web-utility'
import { useEffect, useState } from 'react'

type UnleashToggleType = {
  companyId: string
  flagName: string
}

export function useUnleashToggle({ companyId, flagName }: UnleashToggleType) {
  const [isEnabled, setIsEnabled] = useState<boolean>(false)

  const getUnleashToggle = async () => {
    const { flagIsEnabled } = await getPermissionsFlags(companyId, '')

    setIsEnabled(flagIsEnabled ? flagIsEnabled(flagName) : false)
  }

  useEffect(() => {
    getUnleashToggle()
  })

  return { isEnabled }
}
