import { IconButton } from '@flash-tecnologia/hros-web-ui-v2'

import { ContainerStyled } from './styles'
import { useCellDetails } from './useCellDetails'

type CellDetailsProps = {
  benefitId: string
  benefitName: string
}

export function CellDetails({ benefitId, benefitName }: CellDetailsProps) {
  const { handleOpenDetails } = useCellDetails({ benefitId, benefitName })

  return (
    <ContainerStyled>
      <IconButton
        size="small"
        variant="line"
        icon="IconFileText"
        onClick={handleOpenDetails}
      />
    </ContainerStyled>
  )
}
