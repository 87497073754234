import { Table } from '@flash-tecnologia/hros-web-ui-v2'
import { createContext, ReactNode, useContext } from 'react'

type RootContextState = {
  isLoading: boolean
}

type RootProviderProps = RootContextState & {
  children: ReactNode
}

export const RootContext = createContext({} as RootContextState)

export function RootProvider({
  children,
  isLoading = false,
}: RootProviderProps) {
  return (
    <RootContext.Provider value={{ isLoading }}>
      <Table.Root>{children}</Table.Root>
    </RootContext.Provider>
  )
}

export const useTableContext = () => {
  const context = useContext(RootContext)

  if (!context) {
    throw new Error('useRootContext must be used within a RootProvider')
  }

  return context
}
