import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const WrapperCalculationFormStyled = styled.div`
  display: flex;
  align-items: center;

  column-gap: ${({ theme }) => theme.spacings.xs4};
`

export const WrapperRadioStyled = styled.div`
  display: flex;
  align-items: center;

  width: max-content;

  padding-top: ${({ theme }) => theme.spacings.xs2};
  padding-bottom: ${({ theme }) => theme.spacings.xs2};
  padding-left: ${({ theme }) => theme.spacings.xs};
  padding-right: ${({ theme }) => theme.spacings.xs};

  border-radius: ${({ theme }) => theme.borders.radius.s};

  border: 1px solid ${({ theme }) => theme.colors.neutral90};
`

export const TitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral30};

  font-weight: 600;
`
