// @filename: client.ts
import type { AppRouter } from '@flash-hros/bff'
import { getAccessToken } from '@flash-tecnologia/hros-web-utility'
import { QueryClient } from '@tanstack/react-query'
import { createTRPCReact, httpBatchLink } from '@trpc/react-query'

export const trpc = createTRPCReact<AppRouter>()

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      retry: (failureCount, error: any) => {
        const noRetryErrorCodes = ['NOT_FOUND']

        return (
          !noRetryErrorCodes.includes(error?.data?.code as string) &&
          failureCount < 2
        )
      },
    },
  },
})

export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: `${process.env.BFF_URL}/trpc`,
      headers: async () => {
        const token = await getAccessToken()

        return {
          Authorization: token,
        }
      },
    }),
  ],
})
