import {
  getFromLS,
  segmentEventTracking,
  segmentPageTracking,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility'
import { useCallback } from 'react'

type EventProps = {
  name: string
  params?: Record<string, string>
}

const MODULE_NAME = 'benefit_management'
const BUSINESS_UNIT = 'benefits'

export function useEventTracking() {
  const accessToken = getFromLS('hrosAccessToken')
  const { selectedCompany } = useSelectedCompany()

  const buildTrackProps = useCallback(
    (event: EventProps) => {
      return {
        name: event.name,
        params: {
          companyId: selectedCompany?.id || '',
          companyAdminId: accessToken?.employeeId || '',
          ...event?.params,
        },
        businessUnit: BUSINESS_UNIT,
        module: MODULE_NAME,
      }
    },
    [accessToken?.employeeId, selectedCompany?.id],
  )

  const trackEvent = useCallback(
    (event: EventProps) => {
      const trackEventProps = buildTrackProps(event)

      return segmentEventTracking(trackEventProps)
    },
    [buildTrackProps],
  )

  const trackPage = useCallback(
    (event: EventProps) => {
      const trackPageProps = buildTrackProps(event)

      return segmentPageTracking(trackPageProps)
    },
    [buildTrackProps],
  )

  return {
    trackEvent,
    trackPage,
  }
}
