import { screenCompanyBenefitsTransform } from '@flash-hros/zod-schemas/transformations/screenCompanyBenefitsTransform'
import { Toggle } from '@flash-tecnologia/hros-web-ui-v2'
import type { ColumnsProp } from 'src/components/Table/Grid'
import { z } from 'zod'

import { CellBenefit } from './Cells/CellBenefit'
import { CellCalculationForm } from './Cells/CellCalculationForm'
import { CellCategories } from './Cells/CellCategories'
import { CellDetails } from './Cells/CellDetails'
import { CellUsageForms } from './Cells/CellUsageForms'

type Benefit = z.infer<typeof screenCompanyBenefitsTransform>

export const Columns: ColumnsProp<Benefit> = [
  {
    Header: 'Benefício',
    accessor: 'name',
    Cell: ({ row }) => (
      <CellBenefit
        emoji={row.original.emoji}
        isPAT={row.original.isPAT}
        name={row.original.name}
      />
    ),
    disableSortBy: true,
  },
  {
    Header: 'Categorias',
    accessor: 'categories',
    Cell: ({ row }) => (
      <CellCategories categories={row.original.categories ?? []} />
    ),
    disableSortBy: true,
  },
  {
    Header: 'Forma de cálculo',
    accessor: 'depositTimeUnit',
    Cell: ({ row }) => (
      <CellCalculationForm timeUnit={row.original.depositTimeUnit} />
    ),
    disableSortBy: true,
  },
  {
    Header: 'Formas de uso',
    accessor: 'transactionMethods',
    Cell: ({ row }) => (
      <CellUsageForms usageForms={row.original.transactionMethods} />
    ),
    disableSortBy: true,
  },
  {
    Header: 'Ativar',
    accessor: 'status',
    Cell: ({ row }) => <Toggle checked={row.original.status} disabled />,
    disableSortBy: true,
  },
  {
    Header: 'Detalhes',
    accessor: 'id',
    Cell: ({ row }) => (
      <CellDetails
        benefitId={row.original.id}
        benefitName={row.original.name}
      />
    ),
    disableSortBy: true,
  },
]
