import { screenBenefitDetailTransform } from '@flash-hros/zod-schemas/transformations/screenBenefitDetailTransform'
import { z } from 'zod'

import * as Box from '../Box'

type DescriptionOfTheInvoiceProps = Pick<
  z.infer<typeof screenBenefitDetailTransform>,
  'descriptionApp'
>

export function DescriptionOfTheInvoice({
  descriptionApp,
}: DescriptionOfTheInvoiceProps) {
  return (
    <Box.Root>
      <Box.Header
        title="Descrição na nota fiscal"
        description={descriptionApp ?? '-'}
      />
    </Box.Root>
  )
}
