import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerSkeletonStyled = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: ${({ theme }) => theme.spacings.xs5};
`

export const HeaderSkeletonStyled = styled(Skeleton)`
  height: 86px;

  border-top-left-radius: ${({ theme }) => theme.borders.radius.m};
  border-top-right-radius: ${({ theme }) => theme.borders.radius.m};
`

export const RowSkeletonStyled = styled(Skeleton)`
  height: 90px;

  &:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.borders.radius.m};
    border-bottom-right-radius: ${({ theme }) => theme.borders.radius.m};
  }
`
