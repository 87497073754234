import { ReactNode } from 'react'

import { ContainerStyled } from './styles'

type RootProps = {
  children: ReactNode
}

export function Root({ children }: RootProps) {
  return <ContainerStyled>{children}</ContainerStyled>
}
