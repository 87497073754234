import { Radio } from '@flash-tecnologia/hros-web-ui-v2'

import * as Box from '../Box'
import {
  TitleStyled,
  WrapperCalculationFormStyled,
  WrapperRadioStyled,
} from './styles'
import { useCalculationForm } from './useCalculationForm'

export function CalculationForm() {
  const { onClickedDay, onClickedMonth, depositTimeUnit } = useCalculationForm()

  return (
    <Box.Root>
      <Box.Header
        title="Forma de cálculo"
        description="Como será feito o cálculo para este benefício."
      />

      <WrapperCalculationFormStyled>
        <WrapperRadioStyled>
          <Radio
            checked={depositTimeUnit === 'month'}
            onChange={onClickedMonth}
          />{' '}
          <TitleStyled variant="body3">Por mês</TitleStyled>
        </WrapperRadioStyled>

        <WrapperRadioStyled>
          <Radio checked={depositTimeUnit === 'day'} onChange={onClickedDay} />{' '}
          <TitleStyled variant="body3">Por dia</TitleStyled>
        </WrapperRadioStyled>
      </WrapperCalculationFormStyled>
    </Box.Root>
  )
}
