import { DescriptionStyled, TitleStyled, WrapperHeaderStyled } from './styles'

type HeaderProps = {
  title?: string
  description?: string
}

export function Header({ title, description }: HeaderProps) {
  return (
    <WrapperHeaderStyled>
      <TitleStyled variant="headline9">{title}</TitleStyled>
      <DescriptionStyled variant="body4">{description}</DescriptionStyled>
    </WrapperHeaderStyled>
  )
}
