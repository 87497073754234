import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { trpc } from 'src/api/client'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

export const useFlashBenefits = () => {
  const { trackEvent, trackPage } = useEventTracking()
  const { selectedCompany } = useSelectedCompany()

  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')

  useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm)
    },
    600,
    [searchTerm],
  )

  const {
    data: benefits = [],
    isFetching,
    refetch,
  } = trpc.getCompanyBenefits.useQuery(
    {
      name: debouncedSearchTerm,
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event?.target.value ?? ''

      if (value !== searchTerm) {
        setSearchTerm(value)
      }
    },
    [searchTerm],
  )

  const handleSearchFocus = useCallback(() => {
    trackEvent({
      name: 'FlashOS_BM_Search_Click',
    })
  }, [trackEvent])

  useEffect(() => {
    trackPage({ name: 'FlashOS_BM_View' })
  }, [trackPage])

  useEffect(() => {
    if (selectedCompany.id) {
      refetch()
    }
  }, [refetch, selectedCompany.id])

  return {
    benefits,
    totalBenefit: benefits.length,
    isLoading: isFetching,
    searchTerm,
    handleSearchChange,
    handleSearchFocus,
  }
}
