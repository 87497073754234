import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: ${({ theme }) => theme.spacings.xs3};
`

export const WrapperHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: ${({ theme }) => theme.spacings.xs5};
`

export const TitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral30};
`

export const DescriptionStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral50};
`
