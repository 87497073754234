import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const WrapperTransactionMethodStyled = styled.div`
  display: grid;

  grid-template-columns: 40px auto;

  column-gap: ${({ theme }) => theme.spacings.xs3};

  align-items: center;

  padding: ${({ theme }) => theme.spacings.xs1};

  border-radius: ${({ theme }) => theme.borders.radius.s};

  border: 1px solid ${({ theme }) => theme.colors.neutral90};
`

export const WrapperTextStyled = styled.div`
  display: flex;
  flex-direction: column;
`

export const TitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral30};
`

export const DescriptionStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral50};
`
