import { screenBenefitDetailTransform } from '@flash-hros/zod-schemas/transformations/screenBenefitDetailTransform'

import * as Box from '../Box'
import { TitleStyled, WrapperUsageRulesStyled } from './styles'

type UsageRulesProps = Pick<
  typeof screenBenefitDetailTransform._output,
  'categories'
>

export function UsageRules({ categories }: UsageRulesProps) {
  return (
    <Box.Root>
      <Box.Header
        title="Regras de uso"
        description="O que você precisa saber para utilizar esse benefício."
      />

      {categories?.map((category, key) => (
        <WrapperUsageRulesStyled key={key}>
          <TitleStyled variant="headline9">{category}</TitleStyled>
        </WrapperUsageRulesStyled>
      ))}
    </Box.Root>
  )
}
