import { Icons, Skeleton, Unstable } from '@flash-tecnologia/hros-web-ui-v2'

import { CalculationForm } from './components/CalculationForm'
import { DescriptionOfTheInvoice } from './components/DescriptionOfTheInvoice'
import { FormsOfUse } from './components/FormsOfUse'
import { UsageRules } from './components/UsageRules'
import {
  ButtonCancelStyled,
  ButtonConfirmStyled,
  ContainerStyled,
  FooterStyled,
} from './styles'
import { useDrawerBenefitDetails } from './useDrawerBenefitDetails'

export function DrawerBenefitDetails() {
  const {
    benefit,
    benefitName,
    handleClose,
    open,
    isLoading,
    mutateIsLoaging,
    onClickConfirmed,
  } = useDrawerBenefitDetails()

  return (
    <Unstable.Drawer
      title={`Detalhes de ${benefit?.name ?? benefitName}`}
      open={open}
      onClose={handleClose}
    >
      <ContainerStyled>
        {isLoading ? (
          <Skeleton variant="rounded" height="180px" width="100%" />
        ) : (
          <>
            {benefit?.categories && (
              <UsageRules categories={benefit.categories} />
            )}

            {benefit?.transactionMethods && (
              <FormsOfUse
                transactionMethods={benefit.transactionMethods as never}
              />
            )}

            {benefit?.descriptionApp && (
              <DescriptionOfTheInvoice
                descriptionApp={benefit.descriptionApp}
              />
            )}

            {benefit?.depositTimeUnit && <CalculationForm />}
          </>
        )}
      </ContainerStyled>

      <FooterStyled>
        <ButtonCancelStyled variant="primary" onClick={handleClose}>
          Cancelar
        </ButtonCancelStyled>

        <ButtonConfirmStyled
          size="large"
          variant="primary"
          onClick={onClickConfirmed}
          disabled={isLoading || mutateIsLoaging}
        >
          Confirmar alterações <Icons name="IconCheck" />
        </ButtonConfirmStyled>
      </FooterStyled>
    </Unstable.Drawer>
  )
}
