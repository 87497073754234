/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconTypes, Table } from '@flash-tecnologia/hros-web-ui-v2'
import { MouseEventHandler, ReactNode } from 'react'
import { Column } from 'react-table'

import { useTableContext } from './Root'
import {
  ContainerSkeletonStyled,
  HeaderSkeletonStyled,
  RowSkeletonStyled,
} from './styles'

export type ColumnsProp<T extends Record<string, any>> = (Column<T> & {
  disableSortBy?: boolean
})[]

type GridProps<T extends Record<string, any>> = {
  data: T[]
  columns: ColumnsProp<T>
  rowIdKey: Extract<keyof T, string>
  emptyStateMessage: string
  pageSize: number
  pageCount?: number
  showCheckbox?: boolean
  actions?: {
    key: string
    label: string
    icon: IconTypes
    onClick: (
      rowId: string,
      event: MouseEventHandler<HTMLButtonElement>,
    ) => void
  }[]
  emptyStateChildren?: ReactNode
}

export function Grid<T extends Record<string, any>>({
  data = [],
  columns,
  rowIdKey,
  showCheckbox,
  actions = [],
  emptyStateMessage,
  emptyStateChildren,
  pageSize,
  pageCount,
}: GridProps<T>) {
  const { isLoading } = useTableContext()

  if (isLoading) {
    return (
      <ContainerSkeletonStyled>
        <HeaderSkeletonStyled />

        <RowSkeletonStyled />
        <RowSkeletonStyled />
        <RowSkeletonStyled />
      </ContainerSkeletonStyled>
    )
  }

  return (
    <Table.Grid
      loading={false}
      data={data}
      rowIdKey={rowIdKey}
      columns={columns as never}
      pageSize={pageSize}
      hasPagination={false}
      options={{
        showRowOptions: actions?.length > 0,
        showRowCheckbox: showCheckbox,
        rowOptionsProps: {
          showMenu: actions?.length > 0,
          menuOptions: actions,
        },
      }}
      emptyState={{
        isFiltered: true,
        message: emptyStateMessage,
        children: emptyStateChildren,
      }}
      {...(pageCount && { pageSize })}
    />
  )
}
