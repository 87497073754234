import { useCallback } from 'react'
import { useBenefitDetailsStore } from 'src/pages/BenefitManagement/Tabs/FlashBenefitsTab/stores/useBenefitDetailsStore'

export const useCalculationForm = () => {
  const { setBenefitFields, benefitFields } = useBenefitDetailsStore()

  const onClickedDay = useCallback(() => {
    setBenefitFields({ depositTimeUnit: 'day' })
  }, [setBenefitFields])

  const onClickedMonth = useCallback(() => {
    setBenefitFields({ depositTimeUnit: 'month' })
  }, [setBenefitFields])

  return {
    onClickedDay,
    onClickedMonth,
    depositTimeUnit: benefitFields?.depositTimeUnit,
  }
}
