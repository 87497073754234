import { getQueryKey } from '@trpc/react-query'
import { useCallback, useEffect } from 'react'
import { trpc } from 'src/api/client'
import { useToast } from 'src/utils/hooks/useToast'

import { useBenefitDetailsStore } from '../../stores/useBenefitDetailsStore'

export const useDrawerBenefitDetails = () => {
  const {
    benefitId,
    benefitName,
    open,
    handleClose,
    setBenefitFields,
    benefitFields,
  } = useBenefitDetailsStore()
  const { toastSuccess, toastError } = useToast()
  const { invalidate } = trpc.useContext()

  const getCompanyBenefitsKey = getQueryKey(trpc.getCompanyBenefits)

  const { data: benefit = null, isLoading } = trpc.getBenefitById.useQuery(
    { benefitId: benefitId as string },
    { enabled: !!benefitId },
  )

  const updateBenefitByIdMutation = trpc.updateBenefitById.useMutation()

  const onClickConfirmed = useCallback(() => {
    updateBenefitByIdMutation.mutate(
      {
        benefitId,
        depositTimeUnit: benefitFields?.depositTimeUnit ?? 'month',
      },
      {
        onSuccess() {
          handleClose()

          toastSuccess({ title: 'Forma de cálculo editada com sucesso!' })

          invalidate(undefined, { queryKey: getCompanyBenefitsKey })
        },
        onError() {
          toastError({
            title: 'Erro ao editar a forma de cálculo.',
            description: 'Tente novamente mais tarde.',
          })
        },
      },
    )
  }, [
    benefitFields?.depositTimeUnit,
    benefitId,
    handleClose,
    invalidate,
    getCompanyBenefitsKey,
    toastError,
    toastSuccess,
    updateBenefitByIdMutation,
  ])

  useEffect(() => {
    if (benefit) {
      setBenefitFields(benefit)
    } else {
      setBenefitFields(null)
    }
  }, [benefit, setBenefitFields])

  return {
    open,
    handleClose,
    benefitName,
    benefit: benefitFields,
    isLoading,
    mutateIsLoaging: updateBenefitByIdMutation.isLoading,
    onClickConfirmed,
  }
}
