import { Tab as HROSTab, TabItens } from '@flash-tecnologia/hros-web-ui-v2'

type RootProps = {
  tabs: TabItens[]
  defaultTab?: number
  onTabChanged?: (tabIndex: number) => void
}

export function Root({ tabs, defaultTab = 0, onTabChanged }: RootProps) {
  return (
    <HROSTab
      tabItens={tabs}
      defaultTab={defaultTab}
      onTabChanged={onTabChanged}
    />
  )
}
