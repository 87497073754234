import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.spacings.xs3};
`

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`
