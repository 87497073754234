import { Button, LinkButton } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: ${({ theme }) => theme.spacings.s};

  padding-bottom: 114px;
`

export const ButtonCancelStyled = styled(LinkButton)`
  align-self: center !important; // TODO needs to be adjusted in the future using DS
`

export const ButtonConfirmStyled = styled(Button)`
  align-self: center !important; // TODO needs to be adjusted in the future using DS
`

export const FooterStyled = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral90};

  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.1);

  position: fixed;
  bottom: 0;
  background-color: inherit;
  width: 50%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px 40px;

  z-index: 10;
`
