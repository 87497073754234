import { Typography } from '@flash-tecnologia/hros-web-ui-v2'

import { BoxContentStyled, BoxEmojiStyled, ContainerStyled } from './styles'

type CellBenefitProps = {
  emoji: string
  name: string
  isPAT: boolean
}

export function CellBenefit({ emoji, isPAT, name }: CellBenefitProps) {
  return (
    <ContainerStyled>
      <BoxEmojiStyled>
        <img src={emoji} alt={name} />
      </BoxEmojiStyled>

      <BoxContentStyled>
        <Typography variant="body3">{name}</Typography>

        {isPAT && <Typography variant="body4">PAT</Typography>}
      </BoxContentStyled>
    </ContainerStyled>
  )
}
