import { TagFilter } from '@flash-tecnologia/hros-web-ui-v2'

export function CalculationMethod() {
  return (
    <TagFilter
      filterLabel="Forma de cálculo"
      hasLeftIcon={false}
      variant="secondary"
      disabled
    />
  )
}
