import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { Fragment } from 'react'
import { Outlet } from 'react-router-dom'
import * as Header from 'src/components/Header'
import * as Tab from 'src/components/Tab'
import { useUnleashToggle } from 'src/utils/hooks/useUnleashToggle'

import { useTabPage } from './Hooks/useTabPage'

const BASE_PATH = '/benefit-management'
const AVAILABLE_TABS = ['', 'external-benefit']

export function BenefitManagement() {
  const { selectedCompany } = useSelectedCompany()

  const { isEnabled } = useUnleashToggle({
    companyId: selectedCompany.id,
    flagName: 'EVOLUTION_HROS_BENEFIT_MANAGEMENT_XP',
  })

  const { currentTab, setParams } = useTabPage(BASE_PATH, AVAILABLE_TABS)

  const tabItems = [
    {
      label: 'Benefícios Flash',
      component: <Outlet />,
    },
    {
      label: 'Benefícios Externos',
      component: <Outlet />,
    },
  ].filter(Boolean) as []

  return isEnabled ? (
    <>
      <Header.Root>
        <Header.Texts
          title="Benefícios da empresa"
          description="Tenha visibilidade, ative ou altere atribuições dos benefícios da empresa"
        />
      </Header.Root>

      <PageContainer>
        <Tab.Root
          tabs={tabItems}
          defaultTab={currentTab}
          onTabChanged={setParams}
        />
      </PageContainer>
    </>
  ) : (
    <Fragment />
  )
}
